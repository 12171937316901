import * as React from "react";
import connectStore from "../store";

function KibakiCorruptionProfile ({ year, amount }: { year: number, amount: string }) {
  return (
    <div className="sub-text">
      <div className="sub-text">Kibaki’s Regime came into power largely on an anti-corruption platform, promising to recover money stolen during Moi’s rule. The worst year was {year}, which saw {amount} misappropriated.</div>
      <div className="sub-text">The most common forms of corruption during this era were:</div>
      <ul>
        <li>Fraudulent payment from public revenue (Payment for goods or services not rendered or not fit for use, or payment to persons not entitled to payment)</li>
        <li>Willful failure to comply with procurement laws</li>
      </ul>
      <div className="sub-text">Ministries most involved in corruption scandals:</div>
      <ul>
        <li>Ministry of Local government - 4 scandals</li>
        <li>Ministry of Finance - 3 scandals</li>
        <li>Ministry of Lands - 3 scandals</li>
        <li>Ministry of Tourism - 3 scandals</li>
      </ul>
      <div className="sub-text">Government departments most involved in corruption scandals: </div>
      <ul>
        <li>Department of Tourism - 4 scandals</li>
        <li>Treasury - 3 scandals</li>
      </ul>
      <div className="sub-text">Government agencies most involved in corruption scandals: </div>
      <ul>
        <li>Office of the President - 3 scandals</li>
        <li>Kenya Pipeline Company - 3 scandals</li>
        <li>KEMRI - 3 scandals</li>
      </ul>
    </div>
  )
}

export default connectStore(KibakiCorruptionProfile);