import * as React from "react";
import connectStore from "../store";
import { IStoreProps } from "../store/interface";
import ExploreScandle from "../components/exploreScandle";

import title from '../assets/Title.png';
import { ICorruption } from "../interfaces/ICorruption";

const sortType: any = {
  AmountAssending: 'Amount: Assending',
  AmountDescending: 'Amount: Descending',
  FromYearAssending: 'From Year: Assending',
  FromYearDescending: 'From Year: Descending',
  ToYearAssending: 'To Year: Assending',
  ToYearDescending: 'To Year: Descending'
}

function getList (prop: string, list: ICorruption[], isNumber: boolean = false, isCsv: boolean = false) {
  const result: any[] = [];
  list.forEach((r: any) => {
    if (isNumber) {
      result.push(parseInt(r[prop]));
    } else if (isCsv) {
      r[prop].split(',').forEach((t: any) => result.push(t));
    } else {
      result.push(r[prop]);
    }
  })

  const data = result.filter((item, i, ar) => item && ar.indexOf(item) === i);
  data.sort();

  return data;
}

function getOptions (items: any[], text: string) {
  const list = items.map((o, i) => <option value={o} key={i}>{o}</option>)
  list.unshift(<option value="">{`All ${text}`}</option>)

  return list;
}

function ExploreScandles (props: IStoreProps) {
  const { corruptionState, corruptionActions } = props;
  const filterItems = corruptionState.items.filter(a => a.amount > 0);
  filterItems.sort((a, b) => b.amount - a.amount);

  const [items, setItems] = React.useState<ICorruption[]>(filterItems);
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState(sortType.AmountAssending);
  const [regime, setRegime] = React.useState('');
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');
  const [entityType, setEntityType] = React.useState('');
  const [entity, setEntity] = React.useState('');

  const updateItems = () => {
    const searchLower = search ? search.toLowerCase() : '';
    const list = filterItems.filter(c =>
      c.amount > 0 &&
      (
        searchLower === '' ||
        (c.scandalname || '').toLocaleLowerCase().indexOf(searchLower) > -1 ||
        (c.description || '').toLocaleLowerCase().indexOf(searchLower) > -1
      ) &&
      (regime === '' || c.era === regime) &&
      (from === '' || c.startyear >= startyear) &&
      (to === '' || c.startyear <= endyear) &&
      (entityType === '' || c.entitytypes.split(',').indexOf(entityType) > -1) &&
      (entity === '' || c.entitiesinvolved.split(',').indexOf(entity) > -1)
    );

    switch (sort) {
      case sortType.AmountAssending:
        list.sort((a, b) => b.amount - a.amount);
        break;

      case sortType.AmountDescending:
        list.sort((a, b) => a.amount - b.amount);
        break;

      case sortType.FromYearAssending:
        list.sort((a, b) => b.startyear - a.startyear);
        break;

      case sortType.FromYearDescending:
        list.sort((a, b) => a.startyear - b.startyear);
        break;

      case sortType.ToYearAssending:
        list.sort((a, b) => b.endyear - a.endyear);
        break;

      case sortType.ToYearDescending:
        list.sort((a, b) => a.endyear - b.endyear);
        break;

      default:
        break;
    }

    setItems(list);
  }

  React.useEffect(() => {
    if (corruptionState.items.length === 0) {
      corruptionActions.fetch();
    } else {
      const items = corruptionState.items.filter(a => a.amount > 0);
      items.sort((a, b) => b.amount - a.amount)
      setItems(items);
    }
  }, [corruptionActions, corruptionState]);

  if (filterItems.length === 0) {
    return <></>;
  }

  const regimeList = getList('era', filterItems);
  const fromList = getList('startyear', filterItems, true);
  const toList = getList('endyear', filterItems, true);
  const entityTypeList = getList('entitytypes', filterItems, false, true);
  const entityList = getList('entitiesinvolved', filterItems, false, true);

  const startyear = parseInt(from);
  const endyear = parseInt(to);

  return (
    <div>
      <div className="main">
        <div className="explore-wrapper">
          <img src={title} alt="Title" className="title" />
          <div>IN KENYA</div>
        </div>
      </div>
      <div className="container" style={{ border: 'none' }}>
        <div className="row search" style={{ marginTop: 40 }}>
          <div className="page-title col-md-3">Explore The Database</div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-5">
                <div className="s-label">Search</div>
                <div className="s-input">
                  <input value={search} onChange={(e) => setSearch(e.target.value)}></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="s-label">Sort By</div>
                <div className="s-input">
                  <select onChange={(e) => setSort(e.target.value as any)}>
                    {Object.keys(sortType).map((k: string) => <option value={sortType[k]}>{sortType[k]}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-md-3 btn-wrapper">
                <div className="s-label"></div>
                <div className="s-input">
                  <button onClick={() => updateItems()}>Refresh Result</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="s-label">Filter By Regime</div>
                <div className="s-input">
                  <select value={regime} onChange={(e) => setRegime(e.target.value)}>
                    {getOptions(regimeList, 'Regimes')}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="s-label">From</div>
                <div className="s-input">
                  <select value={from} onChange={(e) => setFrom(e.target.value)}>
                    {getOptions(fromList, 'Years')}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="s-label">To</div>
                <div className="s-input">
                  <select value={to} onChange={(e) => setTo(e.target.value)}>
                    {getOptions(toList, "Years")}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="s-label">Filter By Entity Type</div>
                <div className="s-input">
                  <select value={entityType} onChange={(e) => setEntityType(e.target.value)}>
                    {getOptions(entityTypeList, 'Types')}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="s-label">Select Entities</div>
                <div className="s-input">
                  <select value={entity} onChange={(e) => setEntity(e.target.value)}>
                    {getOptions(entityList, 'Entities')}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ExploreScandle items={items} viewAll={false}></ExploreScandle>
      </div>
      <div className="footer" style={{ textAlign: 'center' }}>
        Africa Uncensored | Copyright @ 2022
      </div>
    </div >
  )
}

export default connectStore(ExploreScandles);