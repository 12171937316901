import * as React from 'react';
import { ITimeline } from '../interfaces/ITimeline';
import connectStore from "../store";

import { IStoreProps } from "../store/interface";
import CorruptionProfile from "./corruptionProfile";
import TopScandles from './topScandles';

const $ = (window as any).$;

function Corruption (props: IStoreProps) {
  const { eraState, uhuruState, kibakiState, moiState } = props;
  const [ selected, setSelected ] = React.useState("Uhuru");

  React.useEffect(() => {
    $(window).scroll(() => {
      const cMenu: any = document.getElementById("c-menu");
      const eraProfile: any = document.getElementById("eraProfiles");
      const mainMenu: any = document.getElementById("main-menu");
      const cProfile: any = document.getElementById("c-profile");

      if (!cMenu || !eraProfile || !mainMenu || !cProfile) {
        return;
      }

      const offset = eraProfile.offsetTop + eraProfile.clientHeight - 100;
      if (window.scrollY > offset && window.scrollY) {
        if (cMenu.classList.contains("sticky")) {
          return;
        }
        cMenu.classList.add("sticky");
        mainMenu.style.display = "none";
        cProfile.style.marginTop = "72px";
      } else {
        if (!cMenu.classList.contains("sticky")) {
          return;
        }
        cMenu.classList.remove("sticky");
        mainMenu.style.removeProperty("display");
        cProfile.style.removeProperty("margin-top");
      }
    })
  }, [])

  if (eraState.items.length === 0) {
    return <></>;
  }

  const era = eraState.items.find(i => i.era === selected);
  let timeline: ITimeline[] = [];

  switch (selected) {
    case "Uhuru":
      timeline = uhuruState.items;
      break;

    case "Kibaki":
      timeline = kibakiState.items;
      break;

    case "Moi":
      timeline = moiState.items;
      break;
  }

  return (
    <>
      <div id="eraProfiles" className="container" style={{ border: "none" }}>
        <div className="page-title">Corruption Profiles in each Era</div>
        <div className="sub-text">
          This section examines the number of agencies, ministries, departments, companies and suspects under each regime. The data was curated from several existing sources and aggregated for purposes of comparison. Gaps exist, especially during the Moi regime which was marked by intimidation of the media and civil society organizations.
        </div>
        <div className="sub-text" style={{ margin: "20px 0", fontStyle:"italic" }}>
          Use the links below to switch between the various eras.
        </div>
      </div>
      <div id="c-menu" className="menu">
        <div className="container">
          <div className={`col-sm-4 ${selected === "Uhuru" ? "selected" : ""}`} onClick={() => setSelected("Uhuru") }><span>Uhuru</span><span> (2013-2022)</span></div>
          <div className={`col-sm-4 ${selected === "Kibaki" ? "selected" : ""}`} onClick={() => setSelected("Kibaki") }><span>Kibaki</span><span> (2002-2013)</span></div>
          <div className={`col-sm-4 ${selected === "Moi" ? "selected" : ""}`} onClick={() => setSelected("Moi") }><span>Moi</span><span> (1978-2002)</span></div>
        </div>
      </div>

      <CorruptionProfile era={era} timeline={timeline}></CorruptionProfile>
      <TopScandles era={era}></TopScandles>
    </>
  )
}

export default connectStore(Corruption);