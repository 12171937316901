import * as React from "react";
import { LineChart } from "../charts/lineChart";
import { Amount, Avatar, Color, scrollWindow } from "../helper";
import { IEra } from "../interfaces/IEra";
import { ITimeline } from "../interfaces/ITimeline";
import connectStore from "../store";

import CorruptionEntry from "./corruptionEntry";
import KibakiCorruptionProfile from "./kibakiCorruptionProfile";
import MoiCorruptionProfile from "./moiCorruptionProfile";
import UhuruCorruptionProfile from "./uhuruCorruptionProfile";

interface IProps {
  era: IEra,
  timeline: ITimeline[]
}

function CorruptionProfile (props: IProps) {
  const { era, timeline } = props;
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);
  const ref = React.useRef(null);

  const entries = [{
    data: [era.agencies],
    label: "agencies",
    total: era.agencies
  }, {
    data: [era.ministries],
    label: "ministries",
    total: era.ministries
  }, {
    data: [era.departments],
    label: "departments",
    total: era.departments
  }, {
    data: [era.knownsuspects, era.ghostsuspects],
    label: "suspects",
    total: era.knownsuspects + era.ghostsuspects
  }, {
    data: [era.identifiedcompanies, era.ghostcompanies],
    label: "companies",
    total: era.identifiedcompanies + era.ghostcompanies
  }]

  entries.sort((a, b) => b.total - a.total);
  entries.sort((a, b) => a.data.length - b.data.length);

  timeline.sort((a, b) => b.amount - a.amount);

  const year = timeline[0].year;
  const amount = Amount.toString(timeline[0].amount, true);

  React.useEffect(() => {
    setIsChartDrawn(false);
  }, [era])

  React.useEffect(() => {
    if (isChartDrawn || timeline.length === 0 || !ref.current) {
      return;
    }

    const el: any = ref.current;
    new LineChart(el, timeline);
    setIsChartDrawn(true);
    scrollWindow();
  }, [timeline, isChartDrawn])

  if (timeline.length === 0) {
    return <></>
  }

  return (
    <div id="c-profile" className="container">
      <div className="row cp" style={{ borderBottom: `4px solid ${Color.Blue}` }}>
        <div className="col-sm-8">
          <div className="page-title" style={{ marginBottom: 0, textTransform:"uppercase" }}>{era.era} Regime</div>
          <div className="big-amount">{Amount.toString(era.amounts, true)}</div>
        </div>
        <div className="col-sm-4">
          <img className="cp-img" src={Avatar[era.era]} alt={era.era} />
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-6 col-sm-6 profile-text">
          {era.era === 'Uhuru' ? <UhuruCorruptionProfile year={year} amount={amount} /> : <></>}
          {era.era === 'Kibaki' ? <KibakiCorruptionProfile year={year} amount={amount} /> : <></>}
          {era.era === 'Moi' ? <MoiCorruptionProfile year={year} amount={amount} /> : <></>}
          <div className="sub-text" style={{ color: Color.Grey, marginTop: 20, fontStyle:"italic" }}>
            Gray boxes represent ghost entities
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div ref={ref}></div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        {/*<div className="col-md-4">
          <CorruptionEntry entry={entries[0]}></CorruptionEntry>
        </div>
        <div className="col-md-4">
          <CorruptionEntry entry={entries[1]}></CorruptionEntry>
          <CorruptionEntry entry={entries[2]}></CorruptionEntry>
        </div>
        <div className="col-md-4">
          <CorruptionEntry entry={entries[3]}></CorruptionEntry>
          <CorruptionEntry entry={entries[4]}></CorruptionEntry>
        </div>*/}
        <div className="col-md-4">
          <CorruptionEntry entry={entries[3]}></CorruptionEntry>
        </div>
        <div className="col-md-4">
          <CorruptionEntry entry={entries[4]}></CorruptionEntry>
          <CorruptionEntry entry={entries[1]}></CorruptionEntry>
          <CorruptionEntry entry={entries[2]}></CorruptionEntry>
        </div>
        <div className="col-md-4">

          <CorruptionEntry entry={entries[0]}></CorruptionEntry>
          
        </div>
      </div>
    </div>
  )
}

export default connectStore(CorruptionProfile);