import { BaseActions } from '../base/baseActions'
import { IUhuruActions } from './IUhuruStore'
import { StoreNames } from '../storeNames'
import { ITimeline } from '../../interfaces/ITimeline'

const uhuruActions: IUhuruActions = {
  ...BaseActions.getActions<ITimeline>(StoreNames.Uhuru, 4)
}

export default uhuruActions
