import * as React from "react";
import connectStore from "../store";

function MoiCorruptionProfile ({ year, amount }: { year: number, amount: string }) {
  return (
    <div className="sub-text">
      <div className="sub-text">Moi was the only president to appear as an individual suspect in corruption scandals during his regime. Several corruption scandals surrounded a number of his close aides, and the worst year was {year}, which saw {amount} misappropriated.</div>
      <div className="sub-text">The most common forms of corruption during this era were:</div>
      <ul>
        <li>Fraudulent payment from public revenue (Payment for goods or services not rendered or not fit for use, or payment to persons not entitled to payment)</li>
        <li>Theft</li>
        <li>Fraudulent acquisition of public property</li>
      </ul>
      <div className="sub-text">Ministries most involved in corruption scandals:</div>
      <ul>
        <li>Presidency - 2 scandals</li>
        <li>Ministry of Foreign Affairs - 2 scandals</li>
      </ul>
      <div className="sub-text">Government departments most involved in corruption scandals: </div>
      <ul>
        <li>Lands Registry - 2 scandals</li>
        <li>Treasury - 3 scandals</li>
      </ul>
      <div className="sub-text">Government agencies most involved in corruption scandals: </div>
      <ul>
        <li>National Social Security Fund (NSSF) - 3 scandals</li>
      </ul>
    </div>
  )
}

export default connectStore(MoiCorruptionProfile);