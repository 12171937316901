import { BaseActions } from '../base/baseActions'
import { IKibakiActions } from './IKibakiStore'
import { StoreNames } from '../storeNames'
import { ITimeline } from '../../interfaces/ITimeline'

const kibakiActions: IKibakiActions = {
  ...BaseActions.getActions<ITimeline>(StoreNames.Kibaki, 5)
}

export default kibakiActions
