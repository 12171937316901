import * as React from "react";
import connectStore from "../store";
import { IStoreProps } from "../store/interface";

import Main from '../components/main';
import Timeline from '../components/timeline';
import Scandles from '../components/scandals';
import StolenAmount from '../components/stolenAmount';
import GenderScandle from '../components/genderScandle';
import Corruption from "../components/corruption";

const $ = (window as any).$;

function Home (props: IStoreProps) {
  const { corruptionActions, yearActions, eraActions, uhuruActions, kibakiActions, moiActions } = props;

  React.useEffect(() => {
    corruptionActions.fetch();
    yearActions.fetch();
    eraActions.fetch();
    uhuruActions.fetch();
    kibakiActions.fetch();
    moiActions.fetch();
  }, [corruptionActions, yearActions, eraActions, uhuruActions, kibakiActions, moiActions]);

  React.useEffect(() => {
    $(window).scroll(() => {
      const main: any = document.getElementById("main");
      const mainMenu: any = document.getElementById("main-menu");
      const timeline: any = document.getElementById("timeline");

      if (!main || !mainMenu || !timeline) {
        return;
      }

      const height = main.clientHeight;
      if (window.scrollY > height) {
        if (mainMenu.classList.contains("sticky")) {
          return;
        }
        mainMenu.classList.add("sticky");
        timeline.style.marginTop = "72px";
      } else {
        if (!mainMenu.classList.contains("sticky")) {
          return;
        }
        mainMenu.classList.remove("sticky");
        timeline.style.removeProperty("margin-top");
      }
    })
  }, [])

  const scrollToEl = (id: string) => {
    const el = document.getElementById(id);
    let top = el ? el.offsetTop : 0;
    if (id !== "timeline") {
      top = top - 50;
    }
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Main></Main>
      <div id="main-menu" className="menu">
        <div className="container">
          <div className="col-md-3 col-sm-12" onClick={() => scrollToEl("timeline")} >Timeline</div>
          <div className="col-md-3 col-sm-12" onClick={() => scrollToEl("scandals")} >Scandals</div>
          <div className="col-md-3 col-sm-12" onClick={() => scrollToEl("demographics")} >Demographics</div>
          <div className="col-md-3 col-sm-12" onClick={() => scrollToEl("eraProfiles")} >Era Profiles</div>
        </div>
      </div>
      <Timeline></Timeline>
      <Scandles></Scandles>
      <StolenAmount></StolenAmount>
      <GenderScandle></GenderScandle>
      <Corruption></Corruption>
      <div className="footer">
        <div className="container">
          <div className="conclusion">
            Conclusion
          </div>
          <div style={{ textAlign: 'justify' }}>
            Corruption does not only exist within the scope of government-related entities. It is sustained through an intricate network that involves other non-state actors such as companies, individuals and politicians among others. Due to legal concerns, these other actors are not named explicitly, but scrutiny of the sources we have linked detail their involvement in corruption-related and adjacent activities.
          </div>
          <div style={{ marginTop: 40 }}>
            Africa Uncensored | Copyright @ 2022
          </div>
        </div>
      </div>
    </>
  )
}

export default connectStore(Home);