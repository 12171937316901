import { ActionType } from 'typesafe-actions'
import { IYearState } from './IYearStore'
import { BaseReducer } from '../base/baseReducer'
import { StoreNames } from '../storeNames'
import { IYear } from '../../interfaces/IYear'

const baseReducer = new BaseReducer<IYear>(StoreNames.Year)
const initialState: IYearState = Object.assign(baseReducer.initialState, {
})

const year = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IYearState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default year
