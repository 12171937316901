import * as React from "react";
import scandleChart from "../charts/scandleChart";
import { Color, scrollWindow } from "../helper";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const colors = [Color.DarkBlue, Color.Grey];

interface IProps {
  entry: {
    data: number[],
    label: string,
    total: number
  }
}

function CorruptionEntry (props: IProps) {
  const { data, label, total } = props.entry;
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    setIsChartDrawn(false);
  }, [props.entry])

  React.useEffect(() => {
    if (isChartDrawn) {
      return;
    }

    const el: any = ref.current;
    const width = el.clientWidth
    // const height = el.clientHeight

    // const area = Math.floor((height * width) / era.scandals)
    // const circleSize = Math.sqrt(area) - gap

    // const circleSize = Math.min(36, width / 16);
    const gap = 8;
    const cols = 10; // Math.floor(width / (circleSize + gap))
    const rows = Math.ceil(total / cols)
    const circleSize = (width / cols) - gap;

    scandleChart()
      .selector(el)
      .data(data.map((d, i) => {
        return {
          source: `${ i > 0 ? 'ghost ' : '' }${label}`,
          value: d,
        }
      }))
      // .useWidth(true)
      // .label("Value of producers' sales in 2013, in thousands of dollars")
      .size(circleSize)
      .gap(gap)
      .rows(rows)
      .columns(cols)
      .rounded(true)
      .colors(colors)
      .hideLegend(true)()

    setIsChartDrawn(true);
    scrollWindow();
  }, [isChartDrawn, data, label, total])

  const tooltip = "An agency is a permanent or semi-permanent organization in the machinary of government that is responsible for the oversight and administration of specific functions, such as an administration e.g. KMC, KEMSA"

  return (
    <div className="entry" style={{ marginTop: 20 }}>
      <div className="sub-text" style={{ color: Color.Grey }}>
        <span>{data[0]} {label} { data.length > 1 ? `(${data[1]} ghost)` : ''}</span>
        <Tippy content={tooltip}  interactiveBorder={20} >
          <span className="icon">i</span>
        </Tippy>
      </div>
      <div style={{ marginTop: 5 }} ref={ref}></div>
    </div>
  )
}

export default CorruptionEntry;