import * as React from "react";
import connectStore from "../store";

import { TimelineChart } from "../charts/timelineChart";
import columnChart from "../charts/columnChart";
import { IStoreProps } from "../store/interface";
import { scrollWindow } from "../helper";

import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends RouteComponentProps, IStoreProps {
}

function Timeline (props: IProps) {
  const { yearState, eraState, history } = props;
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (isChartDrawn || yearState.items.length === 0 || !ref.current) {
      return;
    }

    if ((ref.current as any).offsetWidth <= 425) {
      columnChart(ref.current, yearState.items);
    } else {
      new TimelineChart(ref.current, yearState.items);
    }
    setIsChartDrawn(true);
    scrollWindow();
  }, [yearState, isChartDrawn])

  if (yearState.items.length === 0) {
    return <></>;
  }

  const total = eraState.items.find(i => i.era === "Total");

  return (
    <div id="timeline" className="container">

      <div className="page-title">Documenting Kenya’s corruption since 1978</div>
      <div className="sub-text">
        <span>This Corruption platform provides a consolidated source of data and information on corruption scandals, intending to enable transparency around instances of corruption during the current regime and past regimes of the government of Kenya, dating back to 1978. This is essentially systematic documentation from various existing source materials that have been aggregated to present the history and present state of corruption in Kenya. You can read more about the methodology <span className="value red" style={{ cursor: "pointer" }} onClick={() => history.push('/methodology')} >here</span>. We hope that the comprehensive presentation of this information will help users form a clearer image of the resources involved in each regime, their timelines, the demographics of those involved as well as a look into some of the most infamous scandals. In doing so, users may be able to recognize the trends, gaps and characteristics of State plunder to help Kenya’s fight against corruption.</span>
      </div>
      <div className="sub-text">
        <span>Gaps in available data exist, and this constraint should be taken into consideration when analyzing the platform. Users of this platform are encouraged to reach out to us as well as other sources. </span>
      </div>
      <div className="sub-text">
        <span>The platform captures data collected from 1978 – 2022.</span>
      </div>
      <div className="sub-text">
        <span>One of the effects of the runaway corruption tracked over the years has been the ballooning of Kenya's debt, both internal and external. We have developed a clock that helps you keep tabs on Kenya's worrying debt situation. <a href="https://wizileaks.africauncensored.online/debt-clock/">Check it out &gt;&gt;</a></span>
      </div>
      <div style={{ marginTop: "40px" }} ref={ref}></div>


      <div className="page-title">Corruption Throughout the Years</div>
      <div className="sub-text">
        <span>Over the years, a total of  </span>
        <span className="value red">{total ? total.scandals : ''} scandals</span>
        <span>have been documented by trusted media outlets.</span>
      </div>
      <div className="sub-text">
        <span>The following section captures the number and amounts of scandals during each regime, an assessment of gender participation in corruption, corruption profiles during each regime and profiles some of the most highlighted scandals.</span>
      </div>
      <div style={{ marginTop: "40px" }} ref={ref}></div>
      
    </div>
  )
}

export default withRouter(connectStore(Timeline));