import { Color } from "../helper";
import connectStore from "../store";

import { IStoreProps } from "../store/interface";
import ScandleItem from "./scandleItem";

const colors = [Color.Blue, Color.LightBlue];

function Scandles (props: IStoreProps) {
  const { eraState } = props;

  if (eraState.items.length === 0) {
    return <></>;
  }

  const multiple = eraState.items.find(i => i.era === "Multiple");
  const items = eraState.items.filter(i => i.era !== "Total" && i.era !== "Multiple");
  items.sort((a, b) => b.scandals - a.scandals);

  const maxScandle = items[0];
  items.splice(0, 1);

  return (
    <div id="scandals" className="container">
      <div className="page-title">Scandals Per Regime</div>
      <div className="sub-text">
        <span>There is an exponential growth in the number and monetary value of corruption scandals from Moi’s regime through to the Uhuru regime. The corruption scandals during the latter’s regime surpass all previous regime and multi-regime scandals combined in monetary value and scandal count.</span>
      </div>
      <div className="sub-text">
        <span>This, however, does not mean that corruption may have been less in Moi’s or Kibaki’s time in office, the information could be a reflection of reforms, such as the presence of more vigilant oversight agencies as well as an increase in reporting on corruption by the media. However, we similarly cannot overlook the possibility that suspects may have become more audacious in participation in corrupt activities.</span>
      </div>
      <div className="row">
        <div className="col-md-6">
          <ScandleItem
            era={maxScandle}
            title="during"
            subtitle={`the ${maxScandle.era} Regime`}
            color={Color.DarkBlue}>
          </ScandleItem>
        </div>
        <div className="col-md-6">
          {items.map((era, index) =>
            <ScandleItem
              key={index}
              era={era}
              title="during"
              subtitle={`the ${era.era} Regime`}
              color={colors[index]}>
            </ScandleItem>)}
          {!multiple ? <></> : <ScandleItem
            era={multiple}
            title="across multiple regimes"
            color={Color.Grey}>
          </ScandleItem>}
        </div>
      </div>
    </div>
  )
}

export default connectStore(Scandles);