import { BaseActions } from '../base/baseActions'
import { IYearActions } from './IYearStore'
import { StoreNames } from '../storeNames'
import { IYear } from '../../interfaces/IYear'

const yearActions: IYearActions = {
  ...BaseActions.getActions<IYear>(StoreNames.Year, 2)
}

export default yearActions
