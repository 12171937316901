import * as React from 'react';
import { Amount } from "../helper";
import { ICorruption } from "../interfaces/ICorruption";

import defaultImage from '../assets/sgr.jpg';
import { RouteComponentProps, withRouter } from 'react-router';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
interface IItem {
  title: string,
  value: string
}

function ScandleItem (props: IItem) {
  const { title, value } = props
  if (!value) {
    return <></>
  }

  return <div className="sub-text">
    <span className="grey">{title}</span>
    <span className="bold">{value}</span>
  </div>
}

interface IProps extends RouteComponentProps {
  items: ICorruption[],
  viewAll: boolean
}

function ExploreScandle (props: IProps) {
  const { items, viewAll, history } = props;
  const [ selected, setSelect ] = React.useState<number>(0);

  if (items.length === 0) {
    return <></>;
  }

  const scandle = items[selected];

  return (
    <div className="row explore" style={{ marginTop: viewAll ? 40 : 0 }}>
      <div className="visible-xs-block" style={{ marginBottom: 40 }}>
        <Carousel selectedItem={selected} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} onChange={(index) => setSelect(index)}>
            {items.map((s, i) => {
              return (
                <div className="col-md-4 col-sm-6" key={i}>
                  <div className={`corruption ${i === selected ? 'selected' : ''}`} onClick={ () => setSelect(i) }>
                    <img src={s.imageurl || defaultImage} alt={s.scandalname} />
                    <span>{Amount.toString(s.amount, true)}</span>
                    <div>{s.scandalname}</div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      <div className="col-sm-3 explore" style={{ borderRight: '2px solid #9b9b9b' }}>
        <div className="corruption-detail">
          <div className="sub-text grey">Scandal Details</div>
          <div className="page-title">{scandle.scandalname}</div>
          <div className="data">
            <ScandleItem title="Amount:" value={Amount.toString(scandle.amount, true)}></ScandleItem>
            <ScandleItem title="Regime:" value={scandle.era}></ScandleItem>
            <ScandleItem title="Period:" value={`${scandle.startyear}${scandle.endyear && scandle.endyear !== scandle.startyear ? ` - ${scandle.endyear}` : ''}`}></ScandleItem>
            <ScandleItem title="Entities Involved:" value={scandle.entitiesinvolved}></ScandleItem>
          </div>
          {!scandle.description ? <></> :
            <div className="sub-text" style={{textAlign: 'left'}}><span className="grey">Description:</span> {scandle.description}</div>
          }
          {!scandle.mediasource ? <></> :
            <div className="sub-text source">
              <a href={scandle.mediasource} target="_blank" rel="noreferrer">
                <span className="grey">Source: </span>
                <span>{scandle.mediasource}</span>
              </a>
            </div>
          }
        </div>
      </div>
      <div className="col-sm-9" style={{ padding: 0 }}>
        <div className="explore-list hidden-xs" style={{ maxHeight: '100vh', overflow: 'auto' }}>
          {items.map((s, i) => {
            return (
              <div className="col-md-4 col-sm-6" key={i}>
                <div className={`corruption ${i === selected ? 'selected' : ''}`} onClick={ () => setSelect(i) }>
                  <img src={s.imageurl || defaultImage} alt={s.scandalname} />
                  <span>{Amount.toString(s.amount, true)}</span>
                  <div>{s.scandalname}</div>
                </div>
              </div>
            )
          })}
        </div>
        {viewAll ? <span className="view-all" onClick={() => history.push('/explore')} >{`View All Scandals >>`}</span> : <></>}
      </div>

    </div>
  )
}

export default withRouter(ExploreScandle);