import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStore, applyMiddleware, compose, bindActionCreators } from 'redux'
import thunk from 'redux-thunk'

import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'

import reducers from './reducer'
import { IStoreState } from './interface'

import corruptionActions from './corruption/corruptionActions'
import uhuruActions from './uhuru/uhuruActions'
import moiActions from './moi/moiActions'
import kibakiActions from './kibaki/kibakiActions'
import yearActions from './year/yearActions'
import eraActions from './era/eraActions'

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

export const store = createStore(
  reducers(history), // root reducer with router state,
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk
    ),
  ),
);

export const state = () => store.getState();

const connectStore: any = (component: ComponentType<any>) => {
  return connect((state: IStoreState) => ({
    corruptionState: state.corruption,
    uhuruState: state.uhuru,
    moiState: state.moi,
    kibakiState: state.kibaki,
    yearState: state.year,
    eraState: state.era
  }),
  (dispatch: any) => ({
    corruptionActions: bindActionCreators((corruptionActions as any), dispatch),
    uhuruActions: bindActionCreators((uhuruActions as any), dispatch),
    moiActions: bindActionCreators((moiActions as any), dispatch),
    kibakiActions: bindActionCreators((kibakiActions as any), dispatch),
    yearActions: bindActionCreators((yearActions as any), dispatch),
    eraActions: bindActionCreators((eraActions as any), dispatch)
  }), null, { forwardRef: true })(component)
}

export default connectStore
