import { ActionType } from 'typesafe-actions'
import { IUhuruState } from './IUhuruStore'
import { BaseReducer } from '../base/baseReducer'
import { StoreNames } from '../storeNames'
import { ITimeline } from '../../interfaces/ITimeline'

const baseReducer = new BaseReducer<ITimeline>(StoreNames.Uhuru)
const initialState: IUhuruState = Object.assign(baseReducer.initialState, {
})

const uhuru = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IUhuruState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default uhuru
