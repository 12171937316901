import connectStore from "../store";

import { IStoreProps } from "../store/interface";
import GenderScandleItem from "./genderScandleItem";

function GenderScandle (props: IStoreProps) {
  const { eraState } = props;

  const items = eraState.items.filter(i => i.era !== "Total" && i.era !== "Multiple");
  items.sort((a, b) => a.scandals - b.scandals);

  if (eraState.items.length === 0) {
    return <></>;
  }

  let totalMale = 0;
  let totalFemale = 0;

  items.forEach((i) => {
    totalMale += i.male;
    totalFemale += i.female;
  });

  const label = totalMale > totalFemale ? ["Men", "Women"] : ["Women", "Men"]

  return (
    <div id="demographics" className="container">
      <div className="page-title">{label[0]} Carried Out More Scandals Than {label[1]}</div>
      <div className="sub-text">
        <span>Although the amount of women involved in corruption scandals has gradually been increasing, the majority of persons involved is still predominantly male. Among other reasons, this may be explained as follows:
              <ol type='1'>
                <li>The system has a notable gender imbalance in favour of men when filling senior positions in public offices, therefore more men than women have access to significant control over State resources</li>
                <li>Men have been in power longer than women and despite certain reforms, are still the gender-related majority within senior corporate settings. As a result, they have a perceived advantage in establishing significant corruption networks.</li>
              </ol>
</span>
      </div>
      {items.map((era, i) =>
        <GenderScandleItem era={era} key={i}></GenderScandleItem>
      )}
    </div>
  )
}

export default connectStore(GenderScandle);