import { IEra } from "../interfaces/IEra";
import connectStore from "../store";
import { IStoreProps } from "../store/interface";
import ExploreScandle from "./exploreScandle";

interface IProps extends IStoreProps {
  era: IEra
}

function TopScandles (props: IProps) {
  const { corruptionState, era } = props;

  if (corruptionState.items.length === 0) {
    return <></>;
  }

  const items = corruptionState.items.filter(c => c.amount > 0 && c.era === era.era);
  items.sort((a, b) => b.amount - a.amount);

  const topItems = items.splice(0, 12);

  return (
    <div id="topScandles" className="container" style={{ border: 'none' }}>
      <div className="page-title">Top Scandals</div>
      <div className="sub-text">
      Some scandals involved more money than others, whereas some were popularized largely due to the personalities involved. This section lists some of the most renowned corruption scandals by the amounts that were allegedly misappropriated.
      </div>
      <ExploreScandle items={topItems} viewAll></ExploreScandle>
    </div>
  )
}

export default connectStore(TopScandles);