import { ActionType } from 'typesafe-actions'
import { ICorruptionState } from './ICorruptionStore'
import { BaseReducer } from '../base/baseReducer'
import { StoreNames } from '../storeNames'
import { ICorruption } from '../../interfaces/ICorruption'

const baseReducer = new BaseReducer<ICorruption>(StoreNames.Corruption)
const initialState: ICorruptionState = Object.assign(baseReducer.initialState, {
})

const corruption = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: ICorruptionState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default corruption
