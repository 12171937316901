import * as React from "react";
import barChart from "../charts/barChart";
import { Avatar, Color, scrollWindow } from "../helper";
import { IEra } from "../interfaces/IEra";

interface IProps {
  era: IEra,
}

// const colors = [Color.Blue, Color.DarkBlue];

function GenderScandleItem (props: IProps) {
  const { era } = props;
  const ref = React.useRef(null);
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);

  React.useEffect(() => {
    if (isChartDrawn || !ref.current) {
      return;
    }

    const el: any = ref.current;
    const chart = barChart()
      // .color(colors)
      .text(era.male > era.female ? `${era.male}% Male` : `${era.female}% Female`)
      .textAnchor("start");

    const data = [{
      data: era.male,
      label: "Male",
      value: era.male
    }, {
      data: era.female,
      label: "Female",
      value: era.female
    }]

    const d3 = (window as any).d3;
    d3.select(el).datum(data).call(chart);

    setIsChartDrawn(true);
    scrollWindow();
  }, [isChartDrawn, era])

  return (
    <div className="row gender-scandle">
      <div style={{ width: 120 }}>
        <img style={{ height: 70, marginTop: 20 }} src={Avatar[era.era]} alt={era.era} />
      </div>
      <div style={{ width: "calc(100% - 120px)" }}>
        <div ref={ref} className="barchart"></div>
      </div>
    </div>
  )
}

export default GenderScandleItem;