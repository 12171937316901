import { IYear } from "../interfaces/IYear";
import { Color } from "../helper";

interface IItem extends IYear {
  originalValue: number
}

const getValue = (d: IItem) => d.value; // d.value > 0 ? Math.max(d.value, 10) : 0;

export default function columnChart(el: any, items: IYear[]) {
  const d3 = (window as any).d3
  el.classList.add('column-chart');

  const data: IItem[] = items.map(d => ({
    ...d,
    originalValue: d.count, // d.value,
    value: d.count // d.value / Math.pow(10, 9)
  }));

  const maxItem = data.reduce((p, c) => p.value > c.value ? p : c);

  // set the dimensions and margins of the graph
  const margin = {
    right: 20,
    left: 50,
    top: 10,
    bottom: 60
  }

  //making graph responsive
  const default_width = el.clientWidth - margin.left - margin.right;
  const default_height = 400 - margin.top - margin.bottom;
  const width = default_width;
  const height = default_height;
  //end responsive graph code

  // gridlines in y axis function
  function make_y_gridlines() {		
    return d3.axisLeft(y)
  }

  // append the svg object to the body of the page
  var svg = d3.select(el)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");

  // X axis
  var x = d3.scaleBand()
    .range([0, width])
    .domain(data.map(function (d) { return d.year; }))
    .padding(0.2);
  
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))
    .selectAll("text")
    // .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "middle")
    .style("display", function (_: number, i: number) { return i % parseInt(`${2260 / width}`) !== 0 ? 'none' : 'block' });

  // Add Y axis
  var y = d3.scaleLinear()
    .domain([0, Math.ceil(maxItem.value / 5) * 5 /* Math.ceil(maxItem.value / 50) * 50 */ ])
    .range([height, 0]);

    // add the Y gridlines
  svg.append("g")			
    .attr("class", "grid")
    .call(make_y_gridlines()
        .tickSize(-width)
        .tickFormat("")
  )

  svg.append("g")
    .call(d3.axisLeft(y));

  // Add info
  const info = document.createElement("div");
  info.classList.add("info")
  info.style.opacity = "0";
  el.appendChild(info);

  const value = document.createElement("span");
  value.classList.add("value");
  value.classList.add("red");
  info.appendChild(value);

  const year = document.createElement("span")
  year.classList.add("year");
  info.appendChild(year);

  // Bars
  svg.selectAll("mybar")
    .data(data)
    .enter()
    .append("rect")
    .attr("x", function (d: IItem) { return x(d.year); })
    .attr("width", x.bandwidth())
    .attr("fill", Color.Blue)
    // no bar at the beginning thus:
    .attr("height", function () { return height - y(0); }) // always equal to 0
    .attr("y", function () { return y(0); })
    .on('click', function (this: any, d: IItem) {
      d3.select(el).selectAll("rect")
        .attr("fill", Color.Blue)
      d3.select(this)
        .attr("fill", Color.DarkBlue);

      info.style.opacity = "1";
      value.innerText = d.count.toString() + ' scandals'; // Amount.toString(d.originalValue);
      year.innerText = "in " + d.year;
    })

  // Animation
  svg.selectAll("rect")
    .transition()
    .duration(800)
    .attr("y", function (d: IItem) { return y(getValue(d)); })
    .attr("height", function (d: IItem) { return height - y(getValue(d)); })
    .delay(function (_: any, i: number) { return (i * 100) })

  svg.selectAll("rect").each(function (this: any, d: IItem) {
    if (d.value === maxItem.value) {
      this.dispatchEvent(new Event('click'));
    }
  })
}