import * as React from "react";
import connectStore from "../store";
import { IStoreProps } from "../store/interface";

import title from '../assets/Title.png';

function Methodology (props: IStoreProps) {
  return (
    <div>
      <div className="main">
        <div className="explore-wrapper">
          <img src={title} alt="Title" className="title" />
          <div>IN KENYA</div>
        </div>
      </div>
      <div className="container methodology" style={{ border: 'none' }}>
        <div className="sub-text">The information included on this platform was gathered from existing credible sources and was limited to the national government and state agencies and does not delve into county government-specific corruption.</div>
        <div className="sub-text">In this case, a credible source includes but is not limited to the full unclassified classes of primary and secondary credible sources as follows:</div>
        <ul>
          <li>An acclaimed newspaper within and without Kenya</li>
          <li>A court judgment</li>
          <li>A court ruling</li>
          <li>A report by a state agency, public office and state organ</li>
          <li>Reports by non-governmental institutions</li>
          <li>A report of a commission of inquiry</li>
          <li>A published book</li>
          <li>A report by an acclaimed television program</li>
          <li>Reports by an international organization</li>
        </ul>
        <div className="sub-text">This data was then sorted into the regime-specific categories under each of the three Presidents of Kenya since 1978. The platform incorporates scandals uncovered during the reign of the last three presidents as data from the first president’s governing period was scanty and may not provide an accurate picture of corruption during President Jomo Kenyatta’s rule.</div>
        <div className="sub-text">The data was then organized into sections that examined:</div>
        <ul>
          <li>The number of scandals per year</li>
          <li>The most significant years in corruption scandals</li>
          <li>The hierarchy of corruption scandals based on the amounts misappropriated, as relayed by media accounts, from the highest amounts to the lowest</li>
          <li>Corruption by gender</li>
          <li>Government ministries, departments and agencies involved in each regime, as well as the number of scandals they are reported to have been involved in for each president</li>
          <li>Companies that appear in the most scandals, particularly those which are privately-owned, and whether they are real or fictitious/ exist currently or have ceased to exist, as well as their directors</li>
          <li>Whistleblowers, suspects and persons of interest who were listed in multiple scandals</li>
        </ul>
        <div className="page-title">Scandal Categories</div>
        <ol>
          <li className="sub-text">
            <span>Fraud: fraudulent making payment from public revenue</span>
            <ul>
              <li>Most Common: Payment for goods or services not rendered or not fit for use, or payment to persons not entitled to payment, for example, the Goldenberg scandal</li>
              <li>Fraudulent non-payment for goods rendered</li>
              <li>Fraudulent acquisition of public property, for example, the Grand Regency Hotel</li>
              <li>Payment of higher allowances</li>
            </ul>
          </li>
          <li className="sub-text">Willful failure to comply with procurement laws such as using public office to give a benefit to a related company (is a director or relative)</li>
          <li className="sub-text">Theft of resources</li>
          <li className="sub-text">Bribery</li>
        </ol>
        <div className="page-title">Definitions:</div>
        <ol>
          <li className="sub-text">
            <span>Throughout the document, <b>corruption</b> is defined under 
            <a href="https://www.transparency.org/en/what-is-corruption" target="_blank" rel="noreferrer">Transparency international's A-Z of corruption.</a>
            It defines corruption as follows: <b>“as the abuse of entrusted power for private gain.” </b> 
            They further provide the basics of corruption as:</span>
            <ul>
              <li>Public servants demanding or taking money or favours in exchange for services,</li>
              <li>Politicians misusing public money or granting public jobs or contracts to their sponsors, friends and families,</li>
              <li>Corporations bribing officials to get lucrative deals</li>
            </ul>
          </li>
          <li className="sub-text">
            <span>Person(s) of interest: person(s) of interest refers to people who work in the context of public offices of the national government and include:</span>
            <ul>
              <li>
                <span><b>State officer:</b> means a person holding a State office including but not limited to: </span>
                <ul>
                  <li>President; </li>
                  <li>Deputy President;</li>
                  <li>Cabinet Secretary;</li>
                  <li>Member of Parliament;</li>
                  <li>Judges and Magistrates; </li>
                  <li>member of a commission to which Chapter Fifteen applies; </li>
                  <li>holder of an independent office to which Chapter Fifteen applies; </li>
                  <li>member of a county assembly, governor or deputy governor of a county, or other members of the executive committee of a county government; </li>
                  <li>Attorney-General; </li>
                  <li>Director of Public Prosecutions; </li>
                  <li>Secretary to the Cabinet; </li>
                  <li>Principal Secretary; </li>
                  <li>Chief of the Kenya Defence Forces; </li>
                  <li>commander of a service of the Kenya Defence Forces; </li>
                  <li>Director-General of the National Intelligence Service; </li>
                  <li>Inspector-General, and the Deputy Inspectors-General, of the National Police Service; or </li>
                  <li>an office established and designated as a State office by national legislation</li>
                </ul>
              </li>
              <li>
                <span><b>Department:</b> an office within a ministry that is responsible for specific functions, for example, the State department of health</span>
              </li>
              <li>
                <span><b>Agency:</b> a permanent or semi-permanent organization in the machinery of government that is responsible for the oversight and administration of specific functions, such as administration, for example, KEMSA</span>
              </li>
              <li>
                <span><b>Public office:</b> an office in the national government, a county government or the public service, if the remuneration and benefits of the office are payable directly from the Consolidated Fund or directly out of money provided by Parliament</span>
              </li>
              <li>
                <span><b>State organ:</b> a commission, office, agency or other body established under the Kenyan Constitution; or through an Act of Parliament</span>
              </li>
              <li>
                <div><b>Suspects:</b> these are individuals and/or organizations who are suspected to bear the fault and/or who were involved in the said corruption scandals. They are those provided in newspaper articles, court judgments and by reports issued by state organs or public offices.</div>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="footer" style={{ textAlign: 'center' }}>
        Africa Uncensored | Copyright @ 2022
      </div>
    </div >
  )
}

export default connectStore(Methodology);