import { BaseActions } from '../base/baseActions'
import { IEraActions } from './IEraStore'
import { StoreNames } from '../storeNames'
import { IEra } from '../../interfaces/IEra'

const eraActions: IEraActions = {
  ...BaseActions.getActions<IEra>(StoreNames.Era, 3)
}

export default eraActions
