import * as React from "react";
import connectStore from "../store";

import title from '../assets/Title.png'
import briefcase from '../assets/Briefcase.png'

import animation from './animation'
import './animation/style.css'

function Main() {
  React.useEffect(() => {
    animation();
  }, [])

  return (
    <div id="main" className="main">
      <div className="container main-wrapper">
        <img src={title} alt="Title" className="title" />
        <div className="row cd-headline clip is-full-width">
          <div className="col-sm-8 title-text">
            <div>Profiling Corruption Scandals</div>
            <div>in Kenya throughout</div>
            <div>
              <span className='cd-words-wrapper'>
                <b className='is-visible'>The Uhuru Regime</b>
                <b className='is-hidden'>The Moi Regime</b>
                <b className='is-hidden'>The Kibaki Regime</b>
              </span>
            </div>
          </div>
          <div className="col-sm-4">
            <img src={briefcase} alt="briefcase" className="briefcase" />
          </div>
        </div>
        <div className='scroll-downs'>
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <div className="scrollInfo">
            <div> Scroll Down</div>
            <div> To See More </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connectStore(Main);