import { BaseActions } from '../base/baseActions'
import { IMoiActions } from './IMoiStore'
import { StoreNames } from '../storeNames'
import { ITimeline } from '../../interfaces/ITimeline'

const moiActions: IMoiActions = {
  ...BaseActions.getActions<ITimeline>(StoreNames.Moi, 6)
}

export default moiActions
