import * as React from "react";
import scandleChart from "../charts/scandleChart";
import { Avatar, scrollWindow } from "../helper";
import { IEra } from "../interfaces/IEra";
import connectStore from "../store";

interface IProps {
  era: IEra,
  color: string,
  title: string,
  subtitle?: string
}

function ScandleItem (props: IProps) {
  const { era, color, title, subtitle } = props;
  const ref = React.useRef(null);
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);

  React.useEffect(() => {
    if (isChartDrawn || !ref.current) {
      return;
    }

    const el: any = ref.current;

    const width = el.clientWidth
    // const height = el.clientHeight

    // const area = Math.floor((height * width) / era.scandals)
    // const circleSize = Math.sqrt(area) - gap

    // const circleSize = Math.min(36, width / 16);
    const gap = 8;
    const cols = 15; // Math.floor(width / (circleSize + gap))
    const rows = Math.ceil(era.scandals / cols)
    const circleSize = (width / cols) - gap;

    scandleChart()
      .selector(el)
      .data([{
        source: era.era,
        value: era.scandals
      }])
      // .useWidth(true)
      // .label("Value of producers' sales in 2013, in thousands of dollars")
      .size(circleSize)
      .gap(gap)
      .rows(rows)
      .columns(cols)
      .rounded(true)
      .colors([color])
      .hideLegend(true)()

    setIsChartDrawn(true);
    scrollWindow();
  }, [era, isChartDrawn, color])

  const image = Avatar[era.era];

  return (
    <div className="scandle-item">
      <div className="row">
        <div className={image ? 'col-xs-8' : 'col-xs-12'}>
          <div className="sub-text">
            <span style={{ color }}>{era.scandals} scandals</span>
            <span>{title}</span>
          </div>
          {!subtitle ? <></> : <div className="sub-text">
            <span>{subtitle}</span>
          </div>}
        </div>
        {!image ? <></> : <div className="col-xs-4">
          <img className="s-image" src={image} alt={era.era} />
        </div>}
      </div>

      <div style={{ marginTop: 20 }} ref={ref}></div>
    </div>
  )
}

export default connectStore(ScandleItem);