import Kibaki from '../assets/Kibaki.png';
import Moi from '../assets/Moi.png';
import Uhuru from '../assets/Uhuru.png';

declare const $: any;

export const Avatar: any = {
  Kibaki,
  Moi,
  Uhuru
}

export class Color {
  static DarkBlue = "#72CB3A";
  static Blue = "#2B392C";
  static LightBlue = "#33495D";
  static Grey = "#9b9b9b";
  // static ChartBlue = "#064997";
  // static ChartRed = "#e70f51";
}

export class Amount {
  static toString (amount: number, isFullUnit: boolean = false) {
    const t = Math.pow(10, 12);
    if (amount >= t) {
      return `${parseFloat((amount / t).toFixed(1))} ${isFullUnit ? "Trillion" : 'T'}`
    }

    const b = Math.pow(10, 9);
    if (amount >= b) {
      return `${parseFloat((amount / b).toFixed(1))} ${isFullUnit ? "Billion" : 'B'}`
    }

    const m = Math.pow(10, 6);
    return `${parseFloat((amount / m).toFixed(1))} ${isFullUnit ? "Million" : 'M'}`
  }
}

export function scrollWindow () {
  window.scrollTo(window.scrollX, window.scrollY - 1);
  window.scrollTo(window.scrollX, window.scrollY + 1);
}

$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};