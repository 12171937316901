import { Amount, Color } from "../helper";
import { ITimeline } from "../interfaces/ITimeline";

const d3 = (window as any).d3;
const $ = (window as any).$;

interface IItem {
  year: Date,
  amount: number
}

export class LineChart {
  // private isDrawn: boolean = false;
  private el: any;
  private data: IItem[] = [];

  constructor (el: any, list: ITimeline[]) {
    this.el = el;
    this.data = list.map(d => {
      const amount = d.amount;
      return {
        year: new Date(Date.UTC(d.year, 0)),
        amount
      }
    });

    this.draw();
  }

  private draw () {
    // this.isDrawn = true;
    const data = this.data;
    const el = this.el;
    let animated = false;

    d3.select(el).selectAll("svg").remove();

    const margin = {
      right: 30,
      left: 80,
      top: 30,
      bottom: 60
    }

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    //making graph responsive
    const default_width = el.clientWidth - margin.left - margin.right;
    const default_height = 400 - margin.top - margin.bottom;
    const width = default_width;
    const height = default_height;
    //end responsive graph code

    //sort the data by date so the trend line makes sense
    data.sort((a, b) => a.year.getFullYear() - b.year.getFullYear());

    const lastIndex = data.length - 1;
    const maxYear = data[lastIndex].year.getFullYear();
    const minYear = data[0].year.getFullYear();

    data.unshift({
      year: new Date(Date.UTC(minYear - 2, 0)),
      amount: 0
    })

    data.push({
      year: new Date(Date.UTC(maxYear + 1, 0)),
      amount: 0
    })

    // set the ranges
    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    // define the line
    var valueline = d3.line()
      .x(function (d: IItem) {
        return x(d.year);
      })
      .y(function (d: IItem) {
        return y(d.amount);
      });


    // Scale the range of the data
    x.domain(d3.extent(data, (d: IItem) => {
      return d.year;
    }));

    const maxAmount = d3.max(data, (d: IItem) => {
      return d.amount;
    })
    y.domain([0, maxAmount + (maxAmount * 0.1)]);

    // define the line
    d3.line()
      .x((d: IItem) => {
        return x(d.year);
      })
      .y((d: IItem) => {
        return y(d.amount);
      });

    // append the svg object to the body of the page
    var svg = d3.select(el).append("svg")
      .attr("class", "line-chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    // Add the axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x-axis")
      .call(d3.axisBottom(x).ticks(maxYear - minYear).tickFormat((d: Date) => {
        const fraction = Math.ceil((maxYear - minYear) / 5);
        return d.getFullYear() % fraction === 0 ? d.getFullYear() : ''
      }));

    svg.append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(y).tickFormat(function (d: number) {
        return d > 0 ? Amount.toString(d) : ''
      }));

    svg.select('.x-axis').selectAll('.tick')
      .attr("x1", function (this: any, d: Date) {
        const tick = d3.select(this);
        const fraction = Math.ceil((maxYear - minYear) / 5);
        if (d.getFullYear() % fraction === 0) {
          tick.select('line').attr("y2", -height);
          tick.select('line').attr("y1", 10);
        } else {
          tick.node().remove();
        }
      })

    // Add the trendline
    svg.append("path")
      .data([data.filter((_, i) => i > 0 && i < data.length - 1)])
      .attr("class", "line")
      .attr("d", valueline)
      .attr("stroke", Color.DarkBlue)
      .attr("opacity", 0)
      .attr("stroke-width", 2)
      .attr("fill", "#FFFFFF");

    // Add the data points
    svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("class", "dot")
      .attr("r", (d: IItem) => d.amount > 0 ? 2 : 0)
      .attr("cx", (d: IItem) => x(d.year))
      // .attr("cy", (d: IItem) => y(d.amount))
      .attr("cy", y(0))
      .attr("stroke", Color.DarkBlue)
      .attr("stroke-width", 4)
      .attr("fill", Color.DarkBlue)
      .on('mouseover', function (this: any, d: IItem) {
        d3.select(this).transition()
          .duration('100')
          .attr("r", 5);

        div.transition()
          .duration(100)
          .style("opacity", 1);

        div.html(Amount.toString(d.amount))
          .style("left", (d3.event.pageX - 20) + "px")
          .style("top", (d3.event.pageY - 45) + "px");
      })
      .on('mouseout', function (this: any, d: IItem) {
        d3.select(this).transition()
          .duration('200')
          .attr("r", 2);

        div.transition()
          .duration('200')
          .style("opacity", 0);
      })

    $(window).scroll(() => {
      if (!$(el).isInViewport() || animated) {
        return;
      }

      animated = true;
      svg.selectAll(".dot")
        .transition()
        .duration(100)
        .attr("cy", (d: IItem) => y(d.amount))
        .delay(function (d: any, i: number) {
          return i * 100;
        })

      svg.selectAll(".line")
        .transition()
        .duration(data.length * 100)
        .attr("opacity", 1)
    })
  }
}