import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import corruption from './corruption/corruptionReducer'
import uhuru from './uhuru/uhuruReducer'
import kibaki from './kibaki/kibakiReducer'
import moi from './moi/moiReducer'
import year from './year/yearReducer'
import era from './era/eraReducer'

const reducers = (history: any) => combineReducers({
  router: connectRouter(history),
  corruption,
  uhuru,
  kibaki,
  moi,
  year,
  era
})

export default reducers
