import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from "react-router-dom";

import { history, store } from './store';

import './App.css';
import Home from "./views/Home";
import Explore from "./views/Explore";
import Methodology from './views/Methodology'

function App () {
  history.listen(() => {
    window.scrollTo(0, 0);
  })

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path={"/"}
            component={Home}
            exact
          />
          <Route
            path={"/explore"}
            component={Explore}
            exact
          />
          <Route
            path={"/methodology"}
            component={Methodology}
            exact
          />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
