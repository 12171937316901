import * as React from "react";
import connectStore from "../store";

function UhuruCorruptionProfile ({ year, amount }: { year: number, amount: string }) {
  return (
    <div className="sub-text">
      <div className="sub-text">The most documented instances of corruption happened during Uhuru’s Regime. The worst year was {year}, which saw {amount} misappropriated.</div>
      <div className="sub-text">The most common forms of corruption during this era were:</div>
      <ul>
        <li>Willful failure to comply with procurement laws</li>
        <li>Theft</li>
        <li>Fraudulent payment from public revenue (Payment for goods or services not rendered or not fit for use, or payment to persons not entitled to payment)</li>
      </ul>
      <div className="sub-text">Ministries most involved in corruption scandals:</div>
      <ul>
        <li>Ministry of Lands - 10 scandals</li>
        <li>Ministry of Transport, Infrastructure, Housing, Urban Development and Public works - 10 scandals</li>
      </ul>
      <div className="sub-text">Government departments most involved in corruption scandals: </div>
      <ul>
        <li>Devolution department - 8 scandals</li>
        <li>Lands registry - 5 scandals </li>
      </ul>
      <div className="sub-text">Government agencies most involved in corruption scandals: </div>
      <ul>
        <li>Kenya Revenue Authority - 7 scandals </li>
        <li>National Land Commission - 5 scandals</li>
        <li>Judicial Service Commission - 5 scandals</li>
      </ul>
    </div>
  )
}

export default connectStore(UhuruCorruptionProfile);