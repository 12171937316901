import * as React from "react";
import barChart from "../charts/barChart";
import { Amount, Avatar, Color, scrollWindow } from "../helper";
import connectStore from "../store";

import { IStoreProps } from "../store/interface";

const colors = [Color.LightBlue, Color.Blue, Color.DarkBlue];

function StolenAmount (props: IStoreProps) {
  const { eraState } = props;
  const [isChartDrawn, setIsChartDrawn] = React.useState(false);
  const ref = React.useRef(null);

  const total = eraState.items.find(i => i.era === "Total");
  const items = eraState.items.filter(i => i.era !== "Total" && i.era !== "Multiple");
  items.sort((a, b) => a.scandals - b.scandals);

  React.useEffect(() => {
    if (isChartDrawn || items.length === 0 || !ref.current) {
      return;
    }

    const el: any = ref.current;
    var chart = barChart().color(colors).text(`Total: ${total ? Amount.toString(total.amounts) : ''}`);

    var data = items.map(i => {
      return {
        data: i.amounts,
        label: i.era,
        value: i.amounts
      }
    });

    const d3 = (window as any).d3;
    d3.select(el).datum(data).call(chart);

    setIsChartDrawn(true);
    scrollWindow();
  }, [isChartDrawn, items, total])

  if (eraState.items.length === 0) {
    return <></>;
  }

  const maxScandle = items[items.length - 1];

  return (
    <div className="container">
      <div className="page-title">Amounts Misappropriated in each Regime</div>
      
      <div ref={ref} className="barchart"></div>
      <div className="row">
        {items.map((era, index) =>
          <div className="col-lg-4 stolen" key={index}>
            <div className="row">
              <div className="col-xs-4">
                <img src={Avatar[era.era]} alt={era.era} />
              </div>
              <div className="col-xs-8">
                <div className="sub-text" style={{ color: colors[index] }}>{Amount.toString(era.amounts, true)}</div>
                <div className="sub-text">{era.era} Regime</div>
              </div>
            </div>
          </div>)}
      </div>
    </div>
  )
}

export default connectStore(StolenAmount);