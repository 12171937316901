import { Amount, Color } from "../helper";
import { IYear } from "../interfaces/IYear";

const d3 = (window as any).d3;
const $ = (window as any).$;

interface IItem {
  year: Date,
  amount: number,
  radius: number
}

export class TimelineChart {
  // private isDrawn: boolean = false;
  private el: any;
  private data: IItem[] = [];

  constructor(el: any, list: IYear[]) {
    this.el = el;
    this.data = list.map(d => {
      const amount = d.value;
      let radius = 0;
      if (amount) {
        const value = amount / Math.pow(10, 10);
        radius = value > 1 ? Math.ceil(value) * (el.clientWidth > 768 ? 3 : 2) : 4;
      }

      return {
        year: new Date(Date.UTC(d.year, 0)),
        amount,
        radius
      }
    });

    this.draw();
  }

  private draw () {
    // this.isDrawn = true;
    const data = this.data;
    const el = this.el;
    let animated = false;

    d3.select(el).selectAll("svg").remove();

    const maxR = d3.max(data, (d: IItem) => d.radius) + 50;
    const margin = {
      right: 30,
      left: 30,
      top: 0,
      bottom: Math.ceil(maxR / 2) + 40
    }

    //making graph responsive
    const default_width = el.clientWidth - margin.left - margin.right;
    const default_height = (maxR + 80) - margin.top - margin.bottom;
    const width = default_width;
    const height = default_height;
    //end responsive graph code

    //sort the data by date so the trend line makes sense
    data.sort((a, b) => a.year.getFullYear() - b.year.getFullYear());

    const lastIndex = data.length - 1;
    const maxYear = data[lastIndex].year.getFullYear();
    const minYear = data[0].year.getFullYear();

    data.sort((a, b) => b.radius - a.radius);

    data.unshift({
      year: new Date(Date.UTC(minYear - 20, 0)),
      amount: 0,
      radius: 0
    })
    data.push({
      year: new Date(Date.UTC(maxYear + 20, 0)),
      amount: 0,
      radius: 0
    })

    // set the ranges
    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    // Scale the range of the data
    x.domain(d3.extent(data, (d: IItem) => {
      return d.year;
    }));
    y.domain([0, 0]);

    // define the line
    d3.line()
      .x((d: IItem) => {
        return x(d.year);
      })
      .y(0);

    // append the svg object to the body of the page
    var svg = d3.select(el).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

    // Add the axis
    if (width < 500) {
      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(5));
    } else {
      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(maxYear - minYear + 40).tickFormat((d: Date) => {
            return d.getFullYear() % 10 === 0 ? d.getFullYear() : ''
          }));
    }

    // svg.append("g")
    //   .call(d3.axisLeft(y).tickFormat(function (d) {
    //       return "$" + d3.format(".2f")(d)
    //   }));

    // Add info
    const info = document.createElement("div");
    info.classList.add("info")
    info.style.opacity = "0";
    el.appendChild(info);

    const value = document.createElement("span");
    value.classList.add("value");
    value.classList.add("red");
    info.appendChild(value);

    const year = document.createElement("span");
    year.classList.add("year");
    info.appendChild(year);

    // Add the data points
    svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("class", "dot")
      .attr("r", 0)
      .attr("cx", (d: IItem) => x(d.year))
      .attr("cy", y(0))
      .attr("stroke", Color.Blue)
      .attr("stroke-width", 2)
      .attr("fill", `${Color.Blue}44`)
      .on('mouseover', function (this: any, d: IItem) {
        d3.select(this).transition()
            .duration('100')
            .attr("r", d.radius + 5);
      })
      .on('mouseout', function (this: any, d: IItem) {
          d3.select(this).transition()
            .duration('200')
            .attr("r", d.radius);
      })
      .on('click', function (this: any, d: IItem) {
          d3.select(el).selectAll("circle")
            .attr("stroke", Color.Blue)
            .attr("fill", `${Color.Blue}44`)
          d3.select(this)
            .attr("stroke", Color.DarkBlue)
            .attr("fill", `${Color.DarkBlue}44`);

          info.style.opacity = "1";
          value.innerText = Amount.toString(d.amount);
          year.innerText = "in " + d.year.getFullYear();
          // div.html("$" + d3.format(".2f")(d.wage))
          //     .style("left", (d3.event.pageX + 10) + "px")
          //     .style("top", (d3.event.pageY - 15) + "px");
      })

    $(window).scroll(() => {
      if (!$(el).isInViewport() || animated) {
        return;
      }

      animated = true;
      svg.selectAll(".dot")
        .transition()
        .duration(100)
        .attr("r", (d: IItem) => d.radius)
        .delay(function (d: any, i: number) {
          return i * 50;
        })

      svg.selectAll(".dot").each(function (this: any, _: any, i: number) {
        if (i === 1) {
          this.dispatchEvent(new Event('click'));
        }
      })
    })
  }
}