import { BaseActions } from '../base/baseActions'
import { ICorruptionActions } from './ICorruptionStore'
import { StoreNames } from '../storeNames'
import { ICorruption } from '../../interfaces/ICorruption'

const corruptionActions: ICorruptionActions = {
  ...BaseActions.getActions<ICorruption>(StoreNames.Corruption, 1)
}

export default corruptionActions
