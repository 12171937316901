import Axios from 'axios'

const spreadsheetId = '1GrH_KzFcoxBvHA4GHBp82Zne6WQOsV6bqB3GO_2PHIc'

export default Axios.create({
  baseURL: 'https://spreadsheets.google.com/feeds/list/' + spreadsheetId
})

export const apiV4Url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=`
export const sheetNames = [
  '', // DO NOT CHANGE THIS
  'Base Sheet (For Editing)',
  'Yearly Timeline',
  'All Era Stats',
  'Uhuru Timeline',
  'Kibaki Timeline',
  'Moi Timeline'
]