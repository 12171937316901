interface IItem {
  data: any,
  label?: string,
  value: number
}

export default function barChart () {
  const d3 = (window as any).d3
  const $ = (window as any).$

  let margin = { top: 0, right: 0, bottom: 0, left: -1 },
    height = 70,
    barHeight = 70,
    padding = 0,
    text = "",
    textAnchor = "end",
    dataValue = function (d: IItem) { return +d.data; },
    labelValue = function (d: IItem) { return d.label; },
    color = ["#72CB3A", "#2B392C", "#33495D"];

  function chart (selection: any) {
    var node = selection.node();
    var width = node.clientWidth;
    var animated = false;

    d3.select(node).selectAll("svg").remove();

    // node.style.padding = "20px 0";
    selection.each(function (data: any[]) {
      data = data.map(function (d: IItem) {
        return { value: dataValue(d), label: labelValue(d) };
      });

      const sumVals = d3.sum(data, function (d: IItem) { return d.value; });
      const barScale = d3.scaleLinear()
        .domain([0, sumVals])
        .range([0, (width - margin.left - margin.right)]);

      let svg = selection.selectAll("svg").data([data]);
      const gEnter = svg.enter().append("svg").append("g");
      gEnter.append("g").attr("class", "rects")
        .selectAll(".data-rects").data(data).enter()
        .append("rect").attr("class", "data-rects");

      gEnter.selectAll("text.legend")
        .data([text]).enter()
        .append("text")
        .attr("class", "legend")
        .attr("font-size", 12);

      svg = selection.select("svg");
      svg.attr('width', width).attr('height', height);
      const g = svg.select("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      const rectG = g.select("g.rects");
      const dataRects = rectG.selectAll(".data-rects").data(data, function (d: IItem) { return d.label; });
      dataRects.exit().remove();
      dataRects.enter().append("rect").attr("class", "data-rects");
      rectG.selectAll(".data-rects")
        .attr("x", function (d: IItem, i: number) {
          return data.slice(0, i).reduce(function (a, d) { return a + barScale(d.value); }, 1) + (padding / 2);
        })
        .attr("y", height - margin.bottom - barHeight)
        .attr("height", barHeight)
        .attr("fill", function (d: IItem, i: number) { return color[i]; });

      const legendText = g.selectAll("text.legend").data([text], function (d: string) { return d; });
      legendText.enter()
        .append("text")
        .attr("class", "legend");
      legendText.exit().remove();

      g.selectAll("text.legend")
        .attr("y", barHeight / 1.5)
        .attr("x", textAnchor === "end" ? 0 : 20)
        .attr("text-anchor", textAnchor)
        .text(function (d: string) { return d });

      $(window).scroll(() => {
        if (!$(node).isInViewport() || animated) {
          return;
        }

        animated = true;
        rectG.selectAll(".data-rects").transition()
          .duration(200)
          .delay(function (d: any, i: number) {
            return (i * 200)
          })
          .attr("width", function (d: IItem) { return Math.max(barScale(d.value) - (padding / 2), 1); });

        if (textAnchor === "end") {
          g.selectAll("text.legend").transition()
            .duration(200)
            .delay((data.length - 1) * 200)
            .attr("x", data.slice(0).reduce(function (a, d) { return a + barScale(d.value); }, 1) + (padding / 2) - 20);
        }
      })
    });
  }

  chart.margin = function (_: any) {
    if (!arguments.length) return margin;
    margin = _;
    return chart;
  };

  // chart.width = function (_: any) {
  //   if (!arguments.length) return width;
  //   width = _;
  //   return chart;
  // };

  // chart.height = function (_: any) {
  //   if (!arguments.length) return height;
  //   height = _;
  //   return chart;
  // };

  chart.dataValue = function (_: any) {
    if (!arguments.length) return chart;
    dataValue = _;
    return chart;
  };

  chart.labelValue = function (_: any) {
    if (!arguments.length) return chart;
    labelValue = _;
    return chart;
  };

  chart.color = function (_: any) {
    if (!arguments.length) return chart;
    color = _;
    return chart;
  };

  chart.text = function (_: any) {
    if (!arguments.length) return chart;
    text = _;
    return chart;
  };

  chart.textAnchor = function (_: any) {
    if (!arguments.length) return chart;
    textAnchor = _;
    return chart;
  };

  return chart;
}