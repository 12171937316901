import { ActionType } from 'typesafe-actions'
import { BaseTypes, IBaseState, getType } from './IBaseStore'

export class BaseReducer<T> {
  private name: string = ''

  constructor (name: string) {
    this.name = name;
  }

  get initialState (): IBaseState<T> {
    return {
      items: []
    }
  }

  async dispatch (state: IBaseState<T> = this.initialState, { type, payload }: ActionType<any>): Promise<IBaseState<T>> {
    let newState: IBaseState<T> = state;
    switch (type) {
      case getType(BaseTypes.SET_ITEMS, this.name):
        newState.items = payload;
        break

      default:
        break
    }

    return Object.assign({}, state, newState)
  }
}
