import { BaseTypes, IBaseActions, getType } from './IBaseStore'
import { action } from '../actions';

import Api, { apiV4Url, sheetNames } from '../../api'

declare const d3: any;

const TEXT = '$t'
const KEY_PREFIX = 'gsx$'

// const getDataFromSheet = async (index: number) => {
//   const response = await Api.get(`${index}/public/values?alt=json`);
//   const sheetData: any[] = []
//   response.data.feed.entry.forEach((obj: any) => {
//     const dataObj: any = {}
//     let prop: string = ''

//     for (const key of Object.keys(obj)) {
//       prop = key.replace(KEY_PREFIX, '')
//       if (key !== prop) {
//         const text = obj[key][TEXT];
//         const plainText = text.replace(/,/g, '');

//         const value = parseInt(plainText);
//         const textValue = String(value);

//         if (isNaN(value)) {
//           dataObj[prop] = text;
//         } else if (textValue === plainText || `${textValue}.00` === plainText) {
//           dataObj[prop] = value;
//         } else {
//           dataObj[prop] = text;
//         }
//       }
//     }

//     sheetData.push(dataObj)
//   })

//   return sheetData
// }

const getDataFromSheet = async (index: number) => {
  return new Promise<any[]>((resolve) => {
    d3.csv(`${apiV4Url}${sheetNames[index]}`, (data: any[]) => {
      resolve(data.map(d => {
        const d1: any = {};
        Object.keys(d).forEach(k => {
          const text = d[k];
          const plainText = text.replace(/,/g, '');

          const value = parseFloat(plainText);
          const textValue = String(value);

          const key = k.toLowerCase().replace(/ /g, '').split("(")[0];
          if (isNaN(value)) {
            d1[key] = text;
          } else if (textValue === plainText || `${textValue}.00` === plainText || `${textValue}0%` === plainText || `${textValue}.00%` === plainText) {
            d1[key] = value;
          } else {
            d1[key] = text;
          }
        });
        return d1;
      }))
    });
  })
}

export class BaseActions {
  static getActions<T> (name: string, index: number): IBaseActions<T> {
    return {
      fetch: () => (dispatch: any) => {
        getDataFromSheet(index).then(list => {
          // console.log(list)
          dispatch(action(getType(BaseTypes.SET_ITEMS, name), list))
        })
      },
    }
  }
}
