import { ActionType } from 'typesafe-actions'
import { IMoiState } from './IMoiStore'
import { BaseReducer } from '../base/baseReducer'
import { StoreNames } from '../storeNames'
import { ITimeline } from '../../interfaces/ITimeline'

const baseReducer = new BaseReducer<ITimeline>(StoreNames.Moi)
const initialState: IMoiState = Object.assign(baseReducer.initialState, {
})

const moi = (state = initialState, { type, payload }: ActionType<any>) => {
  const newState: IMoiState = state
  switch (type) {
    default:
      baseReducer.dispatch(newState, { type, payload }).catch()
      break
  }

  return Object.assign({}, state, newState)
}

export default moi
