export interface IBaseActions<T> {
  fetch: () => void,
}

export enum BaseTypes {
  SET_ITEMS = 'SET_ITEMS',
}

export interface IBaseState<T> {
  items: T[]
}

export function getType(type: string, prefix: string) {
  return `${prefix}_${type}`
}